<template>
  <div class="style-by-me">
    <div class="wrapper">
      <div class="title">
        <img src="../assets/me_title_modify.png" alt="TOM STYLE by ME" />
      </div>
      <div class="message">
        <span
          >톰그레이하운드 2021 WINTER 컬렉션을<br />
          나만의 스타일로 커스텀하세요.<br />
          원하는 상/하의를 선택하고<br />
          AR 카메라 기능으로 입어보기까지!</span
        >
      </div>
      <div class="selection">
        <div class="my-style">
          <img :src="getImage()" alt="" />
        </div>
        <div class="closet">
          <div class="top">
            <img class="selected" src="../assets/top/T1.png" alt="top #1" @click="selectTop(1)" />
            <img src="../assets/top/T2.png" alt="top #2" @click="selectTop(2)" />
            <img src="../assets/top/T3.png" alt="top #3" @click="selectTop(3)" />
            <img src="../assets/top/T4.png" alt="top #4" @click="selectTop(4)" />
            <img src="../assets/top/T5.png" alt="top #5" @click="selectTop(5)" />
            <img src="../assets/top/T6.png" alt="top #6" @click="selectTop(6)" />
          </div>
          <div class="bottom">
            <img
              class="selected"
              src="../assets/bottom/B1.png"
              alt="bottom #1"
              @click="selectBottom(1)"
            />
            <img src="../assets/bottom/B2.png" alt="bottom #2" @click="selectBottom(2)" />
            <img src="../assets/bottom/B3.png" alt="bottom #3" @click="selectBottom(3)" />
            <img src="../assets/bottom/B4.png" alt="bottom #4" @click="selectBottom(4)" />
            <img src="../assets/bottom/B5.png" alt="bottom #5" @click="selectBottom(5)" />
            <img src="../assets/bottom/B6.png" alt="bottom #6" @click="selectBottom(6)" />
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <a class="button" :href="getLink()">
        <span>상품 AR 체험하기</span>
      </a>
      <a
        class="button"
        href="http://www.thehandsome.com/ko/magazine/exhibitions/8811297494712"
        target="_blank"
      >
        <span>기획전 바로가기</span>
      </a>
    </div>
    <div class="close" @click="onClickClose">
      <img src="../assets/icon_xbutton_bk.png" alt="close" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      top: 1,
      bottom: 1,
    };
  },
  methods: {
    onClickClose() {
      this.$router.push("/");
    },
    getImage() {
      return `/img/preview/T${this.$data.top}B${this.$data.bottom}.png`;
    },
    getLink() {
      const isAndroidWebView = /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv)/i.test(
        navigator.userAgent
      );
      const url = `fnsholdings.8thwall.app/tomstyle21winter?top=${this.$data.top}&bottom=${this.$data.bottom}`;
      if (isAndroidWebView) {
        return `intent://${url}#Intent;scheme=https;package=com.android.chrome;end`;
      }
      return `https://${url}`;
    },
    selectTop(top) {
      this.$data.top = top;
      const previous = document.querySelector(".closet .top img.selected");
      if (previous) {
        previous.classList.remove("selected");
      }
      const selected = document.querySelector(`.closet .top img:nth-child(${top})`);
      if (selected) {
        selected.classList.add("selected");
      }
    },
    selectBottom(bottom) {
      this.$data.bottom = bottom;
      const previous = document.querySelector(".closet .bottom img.selected");
      if (previous) {
        previous.classList.remove("selected");
      }
      const selected = document.querySelector(`.closet .bottom img:nth-child(${bottom})`);
      if (selected) {
        selected.classList.add("selected");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.style-by-me {
  line-height: 1;
  height: 100%;
  .wrapper {
    position: absolute;
    height: 460px;
    top: calc(50% - 230px);
    left: 0;
    right: 0;
  }
  .title {
    width: calc(811px / 3);
    margin: 0 auto;
    line-height: 0;
    img {
      width: 100%;
    }
  }
  .message {
    font-family: "Noto Sans KR";
    font-size: 13px;
    line-height: 20.6px;
    margin-top: 10px;
  }
  .selection {
    display: flex;
    flex: 1 0;
    margin-top: 23px;
    margin-left: 20px;
    padding-bottom: 33px;
    max-width: 783px;
    .my-style {
      width: 137px;
      height: 293px;
      background-color: #fff;
      flex: 0 0 auto;
      img {
        width: 100%;
        top: calc(50% - 132.925px);
      }
    }
    .closet {
      display: flex;
      margin-left: 11px;
      flex: 1 1 auto;
      flex-direction: column;
      img {
        width: 82px;
        height: 142px;
        border: 1px solid #b3b3b3;
        border-radius: 2px;
        object-fit: contain;
        cursor: pointer;
        margin-right: 6px;
        &.selected {
          border-color: #000;
        }
      }
      .top,
      .bottom {
        width: calc(100vw - 165px);
        height: 143px;
        overflow-x: scroll;
        display: flex;
        flex: 1 1 auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        img {
          &:last-child {
            margin-right: 0;
          }
        }
        &::after {
          // ref: https://chenhuijing.com/blog/flexbox-and-padding/
          content: "";
          padding-right: 12px;
        }
      }
      .top {
        padding-bottom: 5px;
      }
    }
  }
  .buttons {
    position: fixed;
    display: flex;
    left: 0;
    right: 0;
    bottom: 39px;
    margin: 0 auto;
    max-width: 337px;
    .button {
      position: relative;
      display: inline-block;
      flex: 1 1 auto;
      width: calc(492px / 3);
      height: 36px;
      background-color: #fff;
      font-family: "Noto Sans KR";
      font-size: 12px;
      line-height: 30px;
      text-align: left;
      border: 1px solid #eee;
      border-radius: 2px;
      &::after {
        display: inline-block;
        position: absolute;
        content: " ";
        width: 6px;
        height: 9px;
        top: 13px;
        right: 13px;
        background-image: url("../assets/arrow_bk.png");
        background-size: 100%;
        background-position: left;
      }
      &:last-child {
        margin-left: 5px;
      }
      span {
        margin: 0 13px;
        color: #000;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .close {
    position: fixed;
    top: 10px;
    right: 20px;
    img {
      width: 44px;
    }
  }
}

@media screen and (max-height: 630px) {
  .style-by-me {
    .wrapper {
      top: 80px;
    }
  }
}

@media screen and (min-width: 376px) {
  .style-by-me {
    .buttons {
      margin-left: auto;
      max-width: 338px;
      a {
        &:last-child {
          .button {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 823px) {
  .style-by-me {
    .selection {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
